import React,{useEffect} from 'react';
import { Button, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle,Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FadeTransform } from 'react-animation-components';



function ConditionUtilisation(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
       <div>
             <Jumbotron>
                 <div className="container">
                     <div className="row row-header">
                         <div className="col-12 col-sm-6">
                             <h1>Conditions d’utilisation</h1>
                         </div>
                        
                     </div>
                 </div>
             </Jumbotron>
             
             <div className="container row row-content">
              <div className="col-12 col-sm-9 offset-sm-1">
             
1- Les données personnelles désignent les informations permettant d'identifier un utilisateur. Elles sont soumises aux réglementations sur la protection des données (RGPD). eudeliv s'engage à garantir la sécurité de vos données personnelles.
 <br/><br/>

 2- Pour créer un compte, vous devez fournir certaines informations, telles que votre nom, prénom, adresse e-mail et numéro de téléphone. Sans ces informations, l'accès à eudeliv vous sera impossible.
<br/><br/>


3- Certaines informations de votre profil peuvent être accessibles aux autres utilisateurs de la plateforme afin de faciliter le contact.
<br/><br/>

4- Vos données personnelles sont d'une grande importance. eudeliv s'engage à respecter votre vie privée et à ne pas vendre vos données.

<br/><br/>
5- Vos données peuvent être utilisées pour nos communications commerciales (comme les newsletters). Vous pouvez à tout moment vous opposer à cette utilisation en nous écrivant à contact@eudeliv.com.

<br/><br/>
6- Vous avez le droit d'être informé sur l'utilisation de vos données personnelles et de soumettre une demande d'information à eudeliv.
<br/><br/>

7-Vous pouvez demander la modification ou la suppression de vos données personnelles. Nous nous engageons à mettre à jour ou à effacer vos données sur simple demande.
<br/><br/>

8-Vous avez le droit de vous opposer au traitement de vos données personnelles. Nous nous réservons le droit de modifier partiellement ou totalement ces conditions d'utilisation à tout moment.

<br/><br/>
9- eudeliv vous recommande de rester vigilant et de vérifier les colis, produits ou petits paquets qui vous sont remis.
<br/><br/>
10-  eudeliv agit uniquement en tant que plateforme d'annonces et de mise en relation. Les expéditeurs et les destinataires sont seuls responsables des colis envoyés ou reçus et doivent convenir ensemble du coût du service, le cas échéant. Eudeliv se dégage de toute responsabilité concernant la nature et le contenu des colis.
              </div>
          </div>
        </div>
    );
}

export default ConditionUtilisation;
