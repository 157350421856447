import React,{useEffect} from 'react';
import { Button, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle,Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FadeTransform } from 'react-animation-components';



function History(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
       <div>
             <Jumbotron>
                 <div className="container">
                     <div className="row row-header">
                         <div className="col-12 col-sm-6">
                             <h1>Notre histoire</h1>
                         </div>
                        
                     </div>
                 </div>
             </Jumbotron>
             
             <div className="container row row-content">
              <div className="col-12">
              <h3>Probléme/Solution</h3>
              <br/>
              </div>
              <div className="col-12 col-sm-9 offset-sm-1">
              Lors d'un voyage à Nice chez un ami, j'ai oublié les clés de l'appartement à mon départ. En rentrant à Paris, il m'était donc impossible d'accéder à mon domicile. Heureusement, 
        mon ami, qui se rendait à l'aéroport, a confié mes clés à un voyageur faisant le trajet de Nice à Paris, en échange d'une petite somme pour alléger ses frais de voyage. Ainsi, en deux heures,
        j'ai pu récupérer mes clés en toute sécurité et rapidement. 
        Les personnes confrontées à des situations d'urgence similaires peuvent se mettre en relation avec d'autres voyageurs sur Eudeliv
              </div>
              
              <div className="col-12">
              <br/>
              <h3>Comment tout a commencé</h3>
              <br/>
              </div>
              <div className="col-12 col-sm-9 offset-sm-1">
               L'histoire débute lorsque KIDOUN Brahim a rencontré un besoin urgent : obtenir un document essentiel pour une démarche administrative en France, alors que celui-ci se trouvait à Oujda, au Maroc. Les méthodes de livraison classiques auraient nécessité plusieurs semaines pour acheminer le document, ce qui était inacceptable pour Brahim, qui en avait besoin le jour même. Par chance, un membre de sa famille prenait un vol d’Oujda à Paris, et son père a pu lui confier le document.
               <br/><br/>
               Suite à cette expérience, Brahim a voulu explorer le nombre de vols directs de Maroc à Paris, sans tenir compte des bus ou voitures. Il a découvert qu’il y avait des centaines de trajets aériens et donc des centaines de milliers de voyageurs potentiels à qui il pourrait confier des documents.
               <br/><br/>
               Brahim a réalisé que la solution idéale pour envoyer des documents urgents ou d'autres colis (médicaments, cadeaux, bagages, etc.) était d'utiliser l'espace libre dans les bagages des voyageurs plutôt que de recourir à des méthodes de livraison coûteuses et souvent lentes.
               <br/><br/>
               Dans les semaines qui ont suivi, Brahim ne pouvait s'empêcher de penser qu'il devait exister une plateforme centralisant les voyageurs disponibles effectuant ce trajet. Après des recherches en ligne, il a trouvé quelques forums répertoriant les offres de personnes ayant besoin de livraisons urgentes et celles souhaitant transporter des colis. Cependant, le nombre d'annonces était limité et le système était désorganisé, rendant difficile la recherche d'un transporteur.
               <br/><br/>
               Durant ses études au Maroc et en France, ainsi que ses expériences au sein de grandes entreprises et de start-ups à travers la France (Montpellier, Nice, Paris, Lyon), Brahim a adopté l'esprit entrepreneurial. Il a donc immédiatement compris que cette idée avait un potentiel énorme. De nombreuses personnes pourraient bénéficier d'une solution de transport de colis entre particuliers, qui soit à la fois abordable, pratique et conviviale.
               <br/><br/>
               <h4>Un nouveau réseau de livraison grâce aux bagages vides des voyageurs ?</h4>
               <br/><br/>
               À l'époque où Brahim était étudiant au Maroc et en France, son père lui envoyait régulièrement des colis (argent, documents, nourriture, médicaments, etc.) avec des personnes effectuant le même trajet que lui (Casablanca, Lyon, Paris). Brahim voyait cela comme un exemple d'une excellente idée sous-exploitée. L'envoi de colis n'était pas flexible : il fallait toujours attendre qu'une personne de l'entourage prenne la route vers sa ville. Ce délai d'attente était souvent frustrant.
               <br/><br/>    
               Brahim se sentait mal à l'aise à l'idée de confier ses colis gratuitement à des inconnus, surtout lorsqu'il pouvait choisir des solutions de livraison traditionnelles moyennant un coût non négligeable. Tous les voyageurs n'étaient pas disposés à faire ce geste, et il n'y avait pas toujours de bagages disponibles pour transporter des colis. Cependant, beaucoup de personnes avaient des bagages vides prêts à accueillir des colis, et elles seraient ravies d'en réduire le coût du trajet en échange d'une petite compensation. Cette idée est devenue une véritable opportunité de collaboration, bénéfique pour tous.
                     <br/><br/>    
               Après avoir approfondi cette réflexion, Brahim a pris la décision de quitter son emploi pour se consacrer pleinement au développement de ce concept. Grâce aux compétences qu'il avait acquises lors de ses diverses missions dans le domaine informatique, il a travaillé d'arrache-pied pendant huit mois, jour et nuit. Cela l’a conduit à la création de la solution qu’il a baptisée eudeliv (Easy Universal Delivery).
                    

               <br/><br/>

              </div>
          </div>
        </div>
    );
}

export default History;
