import React from 'react';
import { Button, Card, CardImg, CardText, CardBody, CardTitle, Jumbotron, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FadeTransform } from 'react-animation-components';
import ReactPlayer from "react-player"
import { Control, Form } from 'react-redux-form';
import LocationSearchInput from '../common/LocationSearchCities';

const required = (val) => val && val.length > 2;

function Home(props) {


    function handleSearchAnnouncements(values) {
        props.fetchAnnouncements(values.departure, values.destination, values.dateDeparture);
    }

    return (
        <div>
            <div className="homeBackground">
                <div className="home-text">
                    <div className="container text-center">
                        <h2 className="text-white"> Expédiez ou transportez des colis à travers le monde </h2>
                    </div>
                    <br /><br /><br />
                    <div className="container">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>

                                <Form validateOn="blur" model="searchTripsForm" onSubmit={(values) => handleSearchAnnouncements(values)}
                                    validators={{
                                        departure: { required },
                                        destination: { required }
                                    }}>
                                    <Row className="form-group">
                                        <Col md={10}>
                                            <LocationSearchInput model=".departure" id="departure" name="departure"
                                                placeholder="Départ"
                                                formName="searchTripsForm"
                                            />
                                        </Col>

                                    </Row>
                                    <Row className="form-group">
                                        <Col md={10}>

                                            <LocationSearchInput model=".destination" id="destination" name="destination"
                                                placeholder="Destination"
                                                formName="searchTripsForm"
                                            />

                                        </Col>

                                    </Row>

                                    {/* <Row className="form-group text-center">
                                        <Col sm="12" md={{ size: 9, offset: 0 }} >
                                            <Control type="date" model=".dateDeparture" id="dateDeparture" name="dateDeparture"
                                                className="form-control"
                                                component={DatePicker}
                                                onChange={this.handleDepartureDateChange}
                                                minDate={new Date()}
                                                selected={this.state.departureDate}
                                                value={this.state.departureDate}
                                                dateFormat=" dd-MM-yyyy"
                                                placeholderText="date de départ"
                                                locale={fr}
                                            />
                                        </Col>
                                    </Row> */}
                                    <Row className="form-group text-center">
                                        <Col sm="12" md={{ size: 9, offset: 0 }}>
                                            <Control.button className="btn btn-primary" model="searchTripsForm" type="submit" color="primary">
                                                {props.isSearchAnnouncements ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Rechercher"}
                                            </Control.button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        {/* <Link to="/searchannouncements">
                            <Button color="primary" size="lg">Voir les annonces</Button>
                        </Link> */}
                    </div>
                </div>
            </div>
            <br /><br />

            <Jumbotron>
                <div className="container">
                    <div className="row row-header">
                        <div className="col-12 col-sm-6">
                            <h1> Comment ça marche ? </h1>
                        </div>

                    </div>
                </div>
            </Jumbotron>
            <div className="container">
                <br />
                <div className="row align-items-start">

                    <div className="col-12 col-md m-1">
                        <ReactPlayer
                            url="https://www.youtube.com/watch?v=t6bhZzGZ7kg&t"
                            width='100%'
                            controls={true}
                        />
                    </div>
                     

                    <div className="col-12 col-md">
                        <br/>
                        <h5>Les Avantages du transport de colis entre particuliers</h5>
                         <p>
    Le transport de colis entre particuliers offre une alternative économique et rapide par rapport aux services traditionnels d’expédition. Ce système bénéficie à la fois aux expéditeurs et aux voyageurs :
  </p>
                        <ul>
    <li><strong>Pour les expéditeurs</strong> : ils peuvent envoyer leurs colis de manière rapide, sécurisée et à un coût réduit.</li>
    <li><strong>Pour les voyageurs</strong> : ils ont l'opportunité de rentabiliser leurs déplacements en transportant des colis, ce qui permet de réduire leurs frais de voyage.</li>
  </ul>
  <p>
    Un service gagnant-gagnant pour tous !
  </p>
                    </div>

                </div>
            </div>
            <br/><br/>
            <div className="container">
                <div className="row align-items-start">
                    <div className="col-12 col-md m-1">
                        <FadeTransform
                            in
                            transformProps={{
                                exitTransform: 'scale(0.5) translateY(-50%)'
                            }}>
                            <Card>
                                <CardImg src="/assets/images/trip.jpg" alt="" />
                                <CardBody>
                                    <CardTitle className="text-center"><Link to="/addtrip">
                                        <Button color="primary" size="lg">Transporter un colis</Button>
                                    </Link></CardTitle>
                                    <CardText>Optimisez vos trajets en transportant des colis et réduisez vos frais de voyage.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </FadeTransform>
                    </div>
                    <div className="col-12 col-md m-1">
                        <FadeTransform
                            in
                            transformProps={{
                                exitTransform: 'scale(0.5) translateY(-50%)'
                            }}>
                            <Card>
                                <CardImg src="/assets/images/delivery.jpg" alt="" />
                                <CardBody>
                                    <CardTitle className="text-center">  <Link to="/adddelivery">
                                        <Button color="primary" size="lg">Envoyer un colis</Button>
                                    </Link></CardTitle>
                                    <CardText>Envoyez vos colis rapidement et à petit prix grâce aux voyageurs.
                                    </CardText>
                                </CardBody>
                            </Card>
                        </FadeTransform>
                    </div>
                </div>
            </div>
            <br /> <br />
            <Jumbotron>
                <div className="container">
                    <div className="row row-header">
                        <div className="col-12 col-sm-6">
                            <h1> Top 3 des destinations </h1>
                        </div>

                    </div>
                </div>
            </Jumbotron>
            <br /> <br />
            <div className="container">
                <div className="row align-items-start">

                    <div className="col-12 col-md m-1">
                        <FadeTransform
                            in
                            transformProps={{
                                exitTransform: 'scale(0.5) translateY(-50%)'
                            }}>
                            <Card>
                                <CardImg src="/assets/images/top3/paris.jpg" alt="" />
                                <CardBody>
                                    <CardTitle className="text-center">
                                        Paris
                                    </CardTitle>
                                </CardBody>
                            </Card>
                        </FadeTransform>
                    </div>
                    <div className="col-12 col-md m-1">
                        <FadeTransform
                            in
                            transformProps={{
                                exitTransform: 'scale(0.5) translateY(-50%)'
                            }}>
                            <Card>
                                <CardImg src="/assets/images/top3/casablanca.jpeg" alt="" />
                                <CardBody>
                                    <CardTitle className="text-center">
                                        Casablanca
                                    </CardTitle>
                                </CardBody>
                            </Card>
                        </FadeTransform>
                    </div>
                    <div className="col-12 col-md m-1">
                        <FadeTransform
                            in
                            transformProps={{
                                exitTransform: 'scale(0.5) translateY(-50%)'
                            }}>
                            <Card>
                                <CardImg src="/assets/images/top3/bruxelles.jpg" alt="" />
                                <CardBody>
                                    <CardTitle className="text-center">
                                        Bruxelles
                                    </CardTitle>
                                </CardBody>
                            </Card>
                        </FadeTransform>
                    </div>
                </div>
            </div>
            <br />

            <Jumbotron>
                <div className="container">
                    <div className="row row-header">
                        <div className="col-12 col-sm-6">
                            <h1> Top 3 des colis transportés </h1>
                        </div>

                    </div>
                </div>
            </Jumbotron>
            <br /> <br />
            <div className="container">
    <div className="row align-items-start">

        <div className="col-12 col-md m-1">
            <FadeTransform
                in
                transformProps={{
                    exitTransform: 'scale(0.5) translateY(-50%)'
                }}>
                <Card className="d-flex h-100">
                    <CardImg src="/assets/images/top3/document.jpg" alt="" />
                    <CardBody className="d-flex flex-column">
                        <CardTitle className="text-center">
                            Documents
                        </CardTitle>
                        <CardText className="flex-grow-1">
                            Vous avez besoin d’un document depuis votre pays d’origine ? eudeliv vous connecte avec des voyageurs pour acheminer vos documents rapidement et à moindre coût.
                        </CardText>
                    </CardBody>
                </Card>
            </FadeTransform>
        </div>

        <div className="col-12 col-md m-1">
            <FadeTransform
                in
                transformProps={{
                    exitTransform: 'scale(0.5) translateY(-50%)'
                }}>
                <Card className="d-flex h-100">
                    <CardImg src="/assets/images/top3/medicament.jpg" alt="" />
                    <CardBody className="d-flex flex-column">
                        <CardTitle className="text-center">
                            Médicaments
                        </CardTitle>
                        <CardText className="flex-grow-1">
                            Dans certains pays, certains médicaments peuvent être difficiles à obtenir ou indisponibles. Grâce à eudeliv, vous pouvez contourner ces restrictions et recevoir vos médicaments depuis n'importe quel endroit dans le monde.
                        </CardText>
                    </CardBody>
                </Card>
            </FadeTransform>
        </div>

        <div className="col-12 col-md m-1">
            <FadeTransform
                in
                transformProps={{
                    exitTransform: 'scale(0.5) translateY(-50%)'
                }}>
                <Card className="d-flex h-100">
                    <CardImg src="/assets/images/top3/alimentation.jpg" alt="" />
                    <CardBody className="d-flex flex-column">
                        <CardTitle className="text-center">
                            Alimentation
                        </CardTitle>
                        <CardText className="flex-grow-1">
                            Avec eudeliv, expédiez facilement vos produits alimentaires partout dans le monde.
                            Que ce soit des spécialités locales ou des plats faits maison, vos proches pourront en profiter.
                            Confiez vos envois à des voyageurs pour une livraison rapide et économique.
                        </CardText>
                    </CardBody>
                </Card>
            </FadeTransform>
        </div>
    </div>
</div>
            <br />
        </div>
    );
}

export default Home;
