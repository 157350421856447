import React, { useEffect } from 'react';
import { Button, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FadeTransform } from 'react-animation-components';



function HowItWorks(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Jumbotron>
                <div className="container">
                    <div className="row row-header">
                        <div className="col-12 col-sm-6">
                            <h1>Comment ça marche ?</h1>
                        </div>

                    </div>
                </div>
            </Jumbotron>

            <div className="container row row-content">
                <div className="col-12">
                    <h3>Voyageur</h3>
                    <br />
                </div>
                <div className="col-12 col-sm-9 offset-sm-1">
                    <div className="col-12">
                        <h4>1. Publiez votre itinéraire</h4>
                             Indiquez votre destination, la date de départ et le volume des colis que vous pouvez transporter.
                    </div>
                    <br/>
                    <div className="col-12">
                        <h4> Ou cherchez une demande livraison</h4>
                             Consultez les demandes de livraison proposées par les expéditeurs.  
                    </div>
                    <br/><br/>
                    <div className="col-12">
                        <h4>2. Les expéditeurs vous contactent en messages privés </h4>
                        Un expéditeur vous contactera pour discuter des détails de la livraison, y compris le tarif, la date de livraison et le lieu de rencontre.
                    </div>

                    <br/><br/>
                    <div className="col-12">
                        <h4>3. Obtenez votre paiement </h4>
                        Après la livraison, le destinataire récupère le colis et vous remet le paiement en main propre. 
                    </div>
                    <br/><br/>
                </div>
                
                <div className="col-12">
                    <h3>Expéditeur</h3>
                    <br />
                </div>
                <div className="col-12 col-sm-9 offset-sm-1">
                    <div className="col-12">
                        <h4>1. Publiez votre annonce</h4>
                        Indiquez votre destination, votre date de départ et fournissez une description détaillée de votre colis.
                    </div>
                    <br/>
                    <div className="col-12">
                        <h4>Ou cherchez un trajet</h4>
                            Parcourez les trajets proposés par les voyageurs.  
                    </div>
                    <br/><br/>
                    <div className="col-12">
                        <h4>2. Les voyageurs vous contactent en messages privés </h4>
                        Un voyageur vous contactera pour discuter des détails du trajet, tels que le type de colis, le tarif de livraison, la date et le lieu de rencontre.
                    </div>

                    <br/><br/>
                    <div className="col-12">
                        <h4>3. Préparez votre colis </h4>
                        Assurez-vous que votre colis est bien préparé et correctement emballé. 
                    </div>
                    <br/><br/>
                    <div className="col-12">
                        <h4>4. Confirmez la livraison </h4>
                        Une fois la réception validée, confirmez la livraison avec votre destinataire.
                    </div>

              </div>
            </div>
        </div>
      
    );
}

export default HowItWorks;
